import React, { useEffect, useState } from 'react'
import App, { AppProps, AppContext } from 'next/app'
import dynamic from 'next/dynamic'
import moment from 'moment-timezone'

import SnackbarProvider from 'providers/SnackbarProvider'
import TranslationsProvider from 'providers/TranslationsProvider'
import ClubProvider from 'providers/ClubProvider'
import AuthProvider from 'providers/AuthProvider'
import { getTranslationByClub } from 'services/translations'
import { DEFAULT_LANGUAGE, detectToken, retrieveServerCookie } from 'utils/language'
import { IMessages } from '@/shared/models/Messages'
import { getClub, getClubSocialNetworks, getUserClubs } from 'services/clubs'
import { IClub, ISocialNetwork, } from '@/shared/models/Club'
import { startCookieBanner } from 'utils/cookieBanner'
import { getMe, getMenu, getOnboarding } from 'services/user'
import { getServerClubPrefix } from 'utils/clubs'
import { getFonts, getWebsite, getWebsiteMenu, getWebsiteModes, getWebsitePages } from 'services/websites'
import { IWebsite, IWebsiteFont, IWebsiteMenu, IWebsiteMode, IWebsitePage, WebsitePageType } from '@/shared/models/Website'
import { isUserAdmin } from 'utils/users'
import { gaEvent } from 'utils/googleAnalytics'

import 'cookie-banner/dist/cookieBanner.min.css'
import '@/shared/components/Fonts/s50icons/s50icons.css'
import { COOKIE_SETTINGS_NAME, defineCookieDomain, getUserCookie, ICookieSettings, removeAllCookies, S50_LANG_COOKIE, S50_LOGOUT_COOKIE, S50_REFRESH_TOKEN_COOKIE, S50_TOKEN_COOKIE } from 'utils/cookies'
import { IUser } from '@/shared/models/User'
import { parseSeoPath } from 'utils/seoPath'
import { ISection } from '@/shared/models/Section'

const OfflineMessage = dynamic(() => import('components/OfflineMessage/OfflineMessage'), {})
const Loader = dynamic(() => import('@/shared/components/Loader/Loader'), {})
const Error = dynamic(() => import('components/Error/Error'), {})
const CustomThemeProvider = dynamic(() => import('components/ThemeProvider/CustomThemeProvider'), {})

moment.tz.setDefault('Etc/UTC')

interface IMyAppProps extends AppProps {
    messages: IMessages;
    club: IClub;
    userLanguage: string;
    prefix: string;
    website: IWebsite;
    modes: IWebsiteMode[];
    menu: IWebsiteMenu[];
    pages: IWebsitePage[];
    page: IWebsitePage;
    error?: {
        code: number;
        message: string;
        url: string;
    };
    clubFont?: IWebsiteFont;
    token?: string
    me: IUser;
    clubSocialNetworks: ISocialNetwork[],
    sections: ISection[];
    cookieSettings: ICookieSettings;
}

function MyApp({ Component, pageProps, messages, club, userLanguage, prefix, website, modes, error, clubFont, token, me, menu, pages, page, clubSocialNetworks, sections, cookieSettings }: IMyAppProps): React.ReactElement {
    const isClient = typeof window !== 'undefined'
    const [initialUser, setInitialUser] = useState(null)
    const [initialToken, setInitialToken] = useState(null)
    const [initialMenu, setInitialMenu] = useState(null)
    const [initialUserConnectedClubs, setUserConnectedClubs] = useState(null)
    const [initialOnboarding, setInitialOnboarding] = useState(null)
    const [isLoginChecking, setIsLoginChecking] = useState(isClient)
    const logoutCookie = getUserCookie(S50_LOGOUT_COOKIE)

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])

    useEffect(() => {
        // checking for window to ensure we run this only in the client
        // no need to render the user menu on the server, not needed for seo
        if (initialUser && isClient && !initialMenu) {
            Promise.all([
                getMenu({ clubPrefix: prefix }),
                getOnboarding({ clubPrefix: prefix }),
                getUserClubs({ clubPrefix: prefix })
            ]).then(([menu, onboarding, clubs]) => {
                setInitialMenu(menu)
                setInitialOnboarding(onboarding)
                setUserConnectedClubs(clubs)
            })
                .finally(() => {
                    setIsLoginChecking(false)
                })
                .catch(error => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(error)
                    }
                    throw error
                })
        } else {
            setIsLoginChecking(false)
        }
    }, [initialUser, initialMenu, isClient, prefix])

    const checkToken = async () => {
        const cookieToken = await getUserCookie(S50_TOKEN_COOKIE)
        setInitialToken(cookieToken)
        if (cookieToken) {
            const meData = await getMe({ clubPrefix: prefix, token, context: null })
            setInitialUser(meData)
        }
    }

    useEffect(() => {
        if (logoutCookie === '1') {
            const domain = defineCookieDomain()
            removeAllCookies(domain)
            setInitialUser(null)
            setInitialToken(null)
            setInitialMenu(null)
            setInitialOnboarding(null)
            setUserConnectedClubs(null)
        }
    }, [logoutCookie])

    useEffect(() => {
        checkToken()
    }, [token])

    useEffect(() => {
        if (userLanguage && prefix) {
            // cookie banner
            startCookieBanner(userLanguage, prefix, website)
        }
    }, [userLanguage, prefix, website])

    useEffect(() => {
        if (club) {
            gaEvent({
                action: club.id,
                category: 'page_hit',
                additionalProps: {
                    userRole: me ?
                        isUserAdmin(me)
                            ? 'admin'
                            : 'user'
                        : 'visitor'
                }
            })
        }
    }, [club, me])

    function renderComponent(): JSX.Element {
        if (error) {
            return <Error statusCode={error.code} title={error.message} />
        }
        if (typeof window === 'undefined') {
            if (website?.displayed) {
                return <Component {...pageProps} />
            } else {
                return <OfflineMessage />
            }
        }

        if (website?.displayed) {
            return <Component {...pageProps} />
        } else {
            if (isLoginChecking) {
                return <Loader mods='center' />
            } else {
                if (token && me && isUserAdmin(me)) {
                    return <Component {...pageProps} />
                } else {
                    return <OfflineMessage />
                }
            }
        }
    }

    return (
        <ClubProvider
            clubSocialNetworks={clubSocialNetworks}
            initialClub={club}
            initialPrefix={prefix}
            initWebsite={website}
            initModes={modes}
            initFont={clubFont}
            initMenu={menu}
            initPages={pages}
            initPage={page}
        >
            <TranslationsProvider
                initialTranslations={{
                    messages: messages,
                    locale: userLanguage,
                    clubLocale: club?.admin_lang || userLanguage || 'en',
                }}
                initialLoadedTranslations={{
                    [userLanguage]: messages,
                }}
            >
                <AuthProvider
                    initialToken={initialToken}
                    initialUser={initialUser}
                    initialMenu={initialMenu}
                    initialOnboarding={initialOnboarding}
                    club={club}
                    initialUserConnectedClubs={initialUserConnectedClubs}
                    cookieSettings={cookieSettings}
                >
                    <CustomThemeProvider >
                        <SnackbarProvider>
                            {renderComponent()}
                        </SnackbarProvider>
                    </CustomThemeProvider>
                </AuthProvider>
            </TranslationsProvider>
        </ClubProvider>
    )
}

const getInitialProps: typeof App.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext);
    let pageId: number | string = 0

    // if there is an error and nextjs trys to display the error 500 page
    // then don't run through all the steps below needed for a "normal" page
    if (appContext.ctx.pathname === '/_error' || appContext.ctx.pathname === '/404') {
        let pageProps = {}

        if (appContext.Component.getInitialProps) {
            pageProps = await appContext.Component.getInitialProps(appContext.ctx)
        }

        return { pageProps }
    }

    const { ctx } = appContext
    const { req, query } = ctx

    const prefix = getServerClubPrefix(query.prefix as string, req)

    if (!query.slug || query.slug?.[0] === 'web' || query.slug?.length === 0) {
        pageId = WebsitePageType.HOME
    } else {
        const pageSeo = parseSeoPath(query.slug?.[0])
        pageId = pageSeo?.id
    }

    let token: string = detectToken({ req })
    const logoutCookie = retrieveServerCookie({ context: ctx, key: S50_LOGOUT_COOKIE })

    if (logoutCookie === '1') {
        token = null
    }

    const refreshToken = retrieveServerCookie({ context: ctx, key: S50_REFRESH_TOKEN_COOKIE })
    const cookieSettings = retrieveServerCookie({ context: ctx, key: COOKIE_SETTINGS_NAME })
    let userLanguage: string = DEFAULT_LANGUAGE
    const { default: translationKeys } = await import('../../translationKeys.json')

    const getMePromise = token || refreshToken ? getMe({ clubPrefix: prefix.api, token, context: ctx }) : Promise.resolve(null)

    try {
        const [
            modes,
            menu,
            pages,
            clubSocialNetworks,
            club,
            website,
            messages,
            clubFonts,
            me,
        ] = await Promise.all([
            getWebsiteModes({ clubPrefix: prefix.api }).catch(() => Promise.resolve(null)),
            getWebsiteMenu({ clubPrefix: prefix.api }).catch(() => Promise.resolve(null)),
            getWebsitePages({ clubPrefix: prefix.api }).catch(() => Promise.resolve([])),
            getClubSocialNetworks({ clubPrefix: prefix.api }).catch(() => Promise.resolve(null)),
            getClub({ clubPrefix: prefix.api }).catch(() => Promise.resolve(null)),
            getWebsite({ clubPrefix: prefix.api }).catch(() => Promise.resolve(null)),
            getTranslationByClub({ clubPrefix: prefix.api, translationKeys }).catch(() => Promise.resolve(null)),
            getFonts({ clubPrefix: prefix.api }).catch(() => Promise.resolve([])),
            getMePromise,
        ])

        if(ctx.query[S50_TOKEN_COOKIE]) {
            token = ctx.query[S50_TOKEN_COOKIE] as string
        }
        const clubFont: IWebsiteFont = clubFonts.find(i => i.id === website.font?.id)
        const page = pages.find(i => pageId === 'home' && i.type === pageId || i.id === pageId)
        
        if (me) {
            userLanguage = me.language
        }
        
        ctx.query[S50_LANG_COOKIE] = userLanguage

        return {
            ...appProps,
            club,
            messages,
            userLanguage,
            prefix: prefix.api,
            website,
            clubFont,
            modes,
            menu,
            pages,
            page,
            token,
            me,
            clubSocialNetworks,
            cookieSettings: typeof cookieSettings === 'string' ? JSON.parse(cookieSettings) : null 
        }
    } catch (error) {
        return {
            ...appProps,
            userLanguage,
            prefix,
            error: {
                code: (error.code ? error.code : 0),
                message: (error.message ? error.message : ''),
                url: (error.url ? error.url : ''),
            }
        }
    }
}

MyApp.getInitialProps = getInitialProps;

export default MyApp

