import { useEffect, useState } from 'react'

import { getTranslation } from 'services/translations'
import Translations from 'components/Translations/Translations'
import { IMessages } from '@/shared/models/Messages'
import TranslationsContext from 'contexts/translations'
import { defineCookieDomain, S50_LANG_COOKIE, setUserCookie } from 'utils/cookies'
import { useClub } from 'contexts/club'
import moment from 'moment'

interface ITranslationsProps {
    initialTranslations: {
        locale: string;
        messages: IMessages;
        clubLocale?: string;
    };
    initialLoadedTranslations: {[locale: string]: IMessages};
    children: React.ReactNode;
}

export default function TranslationsProvider({ children, initialTranslations, initialLoadedTranslations }: ITranslationsProps): React.ReactElement {
    const [translations, setTranslations] = useState(initialTranslations)
    const [isTranslationsLoading, setIsTranslationsLoading] = useState(false)
    const [loadedTranslations, setLoadedTranslations] = useState(initialLoadedTranslations)
    const { club, prefix } = useClub()

    const loadMoment = async (language) => {
        // not sure why linter not able to resolve module declaration but it's actually working
        switch (language) {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            case 'lu': return await import('moment/locale/lb');
            // @ts-ignore
            case 'de': return await import('moment/locale/de');
            // @ts-ignore
            case 'fr': return await import('moment/locale/fr');
            // @ts-ignore
            case 'fi': return await import('moment/locale/fi');
            // @ts-ignore
            case 'nl': return await import('moment/locale/nl');
            // @ts-ignore
            case 'es': return await import('moment/locale/es');
            default: return null
        }
      }
    useEffect(() => {
        async function effectFn() {
            // we need to load club publishing language for moment
            const locale = translations.clubLocale === 'lu' ? 'lb' : translations.clubLocale
            await loadMoment(translations.clubLocale);
            moment.locale(locale)
        }
        effectFn();
      }, [translations.locale])

    function timePassedFormatter(time: number | Date | string): string{
        const date = typeof time === 'number' ? new Date(time * 1000) : new Date(time)
        const momentDate = moment(date)
        const weeksPast = moment().diff(momentDate, 'week')
        const timePassed = weeksPast > 1 ? momentDate.format('DD.MM.YYYY') : momentDate.fromNow()

        return timePassed.slice(0,1).toUpperCase() + timePassed.slice(1)
    }

    function simpleDateFormatter(time: number | Date | string, formatter?: string): string {
        const fmt = formatter ?? 'Do MMM YYYY'
        const date = typeof time === 'number' ? new Date(time * 1000) : new Date(time)
        const momentDate = moment(date)
        return momentDate.format(fmt)
    }

    function multipleDaysFormatter(from: string, to: ''): string {
        const format = 'D MMMM YYYY'
        if (!from) return ''

        if (moment(to).year() > moment(from).year()) {
            return `${moment(from).format(format)}-${moment(to).format(format)}`
        }

        if (moment(to).month() > moment(from).month()) {
            return `${moment(from).format(format)}-${moment(to).format(format)}`
        }

        if (moment(to).date() > moment(from).date()) {
            return `${moment(from).date()}-${moment(to).format(format)}`
        }

        return moment(from).format(format)
    }

    async function handleLanguageChange(locale: string) {
        let messages: IMessages = loadedTranslations[locale]
        setIsTranslationsLoading(true)

        try {
            if (typeof messages === 'undefined') {
                messages = await getTranslation(locale, translations.messages)

                setLoadedTranslations({
                    ...loadedTranslations,
                    [locale]: messages,
                })
            }

            setTranslations({
                locale,
                messages,
            })
            const custom_domain_online = club?.domain_online ? prefix : ''
            const never = new Date(253402300000000)
            setUserCookie({
                cookieName: S50_LANG_COOKIE,
                value: locale,
                domain: defineCookieDomain(custom_domain_online),
                expires: never,
                path: '/',
                sameSite: 'Lax'
            })
        } finally {
            setIsTranslationsLoading(false)
        }
    }

    async function handleExtendTranslations(newKeys: {[local: string]: IMessages}) {
        const currentLocale = translations.locale
        const locales = Object.keys(newKeys)
        const newLoadedTranslations = {}

        locales.map((locale) => {
            const cachedLocaleKeys = loadedTranslations[locale]
            const newLocaleKeys = newKeys[locale]

            newLoadedTranslations[locale] = { ...cachedLocaleKeys, ...newLocaleKeys }
        })

        setLoadedTranslations(newLoadedTranslations)
        setTranslations(prevTranslations => {
            const {
                messages,
            } = prevTranslations
            return {
                messages: {
                    ...messages,
                    ...newLoadedTranslations[currentLocale]
                },
                locale: currentLocale,
            }
        })
    }

    return(
        <TranslationsContext.Provider
            value={{
                timePassedFormatter,
                loadedTranslations,
                translations,
                isTranslationsLoading,
                detectLanguage: handleLanguageChange,
                extendTranslations: handleExtendTranslations,
                simpleDateFormatter,
                multipleDaysFormatter
            }}
        >
            <Translations>
                {children}
            </Translations>
        </TranslationsContext.Provider>
    )
}